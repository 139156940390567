<template>
  <div class="Trend">
    <H5Navheader :name="'流转趋势'" />
    <div class="main-body">
      <div class="chart-wrap">
        <div class="header">
          <div class="title">每日流转量</div>
        </div>
        <div ref="lineChartRef1" style="width: 100%; height: 450px" />
      </div>

      <!-- <div class="chart-wrap">
        <div class="header">
          <div class="title">大额交易监控</div>
        </div>
        <div ref="lineChartRef2" style="width: 100%; height: 450px" />
      </div> -->
    </div>
    <H5Tab :index="1" />
  </div>
</template>
<script>
import * as echarts from "echarts";
import H5Tab from "@/components/H5Tab";
import H5Navheader from "@/components/H5Navheader";
import { turnoverEveryDay } from "@/api/index.js";
export default {
  name: "Trend",
  components: {
    H5Tab,
    H5Navheader,
  },
  mounted() {
    this.turnoverList();
  },
  data() {
    return {
      lineChart1: undefined,
      lineChart2: undefined,
      option: {
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      },
    };
  },
  methods: {
    /** 渲染echart */
    renderChart() {
      this.lineChart1 = echarts.init(this.$refs.lineChartRef1);
      // this.lineChart2 = echarts.init(this.$refs.lineChartRef2);
      this.lineChart1.setOption(this.option);
      // this.lineChart2.setOption(this.option);
      window.addEventListener("resize", () => {
        this.lineChart1.resize();
        // this.lineChart2.resize();
      });
    },
    /** 获取每日交易金额 */
    turnoverList() {
      turnoverEveryDay({ searchType: "LAST_THIRTY" }).then((res) => {
        // console.log(res)
        const min = res.data.reduce(function (prev, curr) {
          return Number(prev.turnover) < Number(curr.turnover) ? prev : curr;
        });

        const max = res.data.reduce(function (prev, curr) {
          return Number(prev.turnover) > Number(curr.turnover) ? prev : curr;
        });
        this.option = {
          ...this.option,
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.data.map((item) =>
              this.$moment(item.createTime).format("MM.DD")
            ),
          },
          yAxis: {
            type: "value",
            min: (min.turnover * 0.99).toFixed(0),
            max: (max.turnover * 1.01).toFixed(0),
          },
          grid: {
            left: "20%",
          },
          series: [
            {
              data: res.data.map((item) => item.turnover),
              type: "line",
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FD514D", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "#552728", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "transparent", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                normal: {
                  color: "#FD514D",
                },
              },
            },
          ],
        };
        this.renderChart();
      });
    },
    /** 获取大额交易监控 */
  },
};
</script>

<style lang="scss" scoped>
.about {
  .test {
    color: red;
  }
}
</style>
