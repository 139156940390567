<template>
  <div class="header-h5">
      {{name}}
      <div class="icon" @click="goSearch"></div>
  </div>
</template>

<script>
export default {
  props: ['name'],
  data() { 
    return {}
  },
  methods:{
    goSearch() {
      this.$router.push('/search')
      console.log(this.$router)
    }
  }
 }
</script>

<style lang='scss' scoped>
.header-h5 {
  background: #17181A;
  text-align: center;
  font-size: 0.34rem;
  color: #fff;
  padding: 0.27rem 0;
  position: relative;
  .icon {
    width: 0.27rem;
    height: 0.25rem;
    background-image: url('../../assets/nav_icon_search.png');
    background-size: cover;
    position: absolute;
    right: 0.28rem;
    bottom: 0.31rem;
  }
  @media screen and (min-width:960px) {
    display: none;
  }
}
</style>