<template>
  <div :class="`nav-header-pc ${currentScroll >= 200?'purple':''}`">
      <div class="logo" @click="$router.push('/')"></div>
      <div class="nav-list">
        <div class="item" @click="scrollTo(0,800)">
          <router-link to="/index">文化数据</router-link>
        </div>
        <div class="item">
          <router-link to="/trend">交易趋势</router-link>
        </div>
        <div class="item" @click="scrollTo(0,800)">
          <router-link to="/chain/index">链上公示</router-link>
        </div>
        <div class="item" @click="scrollTo(0,800)">
          <router-link to="/networkNode">网络节点</router-link>
        </div>
        <div class="search-box">
          <el-input
            placeholder="请输入交易哈希或者钱包地址"
            suffix-icon="el-icon-search"
            
            v-model="keyword"
            @keyup.enter.native="handleQuery"
            >
          </el-input>
        </div>
      </div>
    </div>
</template>

<script>
import { searchAddrHash } from '@/api/index.js'
import { scrollTo } from '@/utils/scroll-to'

export default {
  data() { 
    return {
      keyword: "",
      currentScroll: 0,
      scrollTo
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollbox, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScrollbox) //页面离开后销毁监听事件
  },
  methods:{
    handleQuery() {
      searchAddrHash({searchAddrHash:this.keyword}).then(res => {
        if (res.code === 500) {
          this.$message.error(res.msg);
        }
        if (res.msg === 'BLOCK_HASH') {
          this.$router.push({path: 'hexDetail',query: {keyword:this.keyword,t: Date.now()}});
        }
        if (res.msg === 'ADDR_DETAIL') {
          this.$router.push({path: 'address',query: {keyword:this.keyword,t: Date.now()}});
        }
        if (res.msg === 'SOURCE_DETAIL') {
          this.$router.push({path: '/chain/data',query: {keyCode:this.keyword,t: Date.now()}});
        }
      })
    },
    handleScrollbox() {
      this.currentScroll = window.pageYOffset //表示当前滚动的位置
      // if(!this.$refs.testref)
      // return;
      // let currentBodayHeight =document.body.clientHeight;
      // if (this.currentScroll+currentBodayHeight >= this.$refs.testref.offsetTop) {
      //   this.fadeIn = true;
      // }else{
      //   this.fadeIn = false;
      // }
    },
    
  }
 }
</script>

<style lang='scss' scoped>
.nav-header-pc {
  background: #17181A;
  &.purple {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: #17181A;
  }
  // height: 70px;
  display: flex;
  align-items: center;
  padding: 30px 105px;
  justify-content: space-between;
  .logo {
    width: 134px;
    height: 47px;
    background-image: url('../../assets/logo.png');
    background-size: cover;
background-position:center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .nav-list {
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 14px;
    .item {
      margin-right: 66px;
      cursor: pointer;
      &:hover {
        color: #FD514D;
      }
    }
  }
  @media screen and (max-width:960px) {
    display: none;
  }
}
</style>
<style lang="scss">
.nav-list {
  .search-box {
    .el-input__inner {
      width: 274px;
      height: 34px;
      border: 2px solid #FD514D;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
      border-radius: 17px;
      background: transparent;
      color: #fff;
      &:focus {
        border-color: #409EFF;
      }
    }
    .el-input__icon {
      color: #FD514D;
      line-height: 34px;
    }
  }
}
</style>